import store from "@/store"

export async function customerHasAuth(to, from, next){
    try {
        let customer = store.getters['Customer/customer'];
        let accessToken = store.getters['Customer/accessToken'];        

        //está vindo de uma pagina externa
        if('accessToken' in to.query){
            accessToken = to.query.accessToken;
            store.dispatch('Customer/setAccessToken', accessToken);
        }

        if(!accessToken){
            throw Error('token not found')
        }

        if(Object.keys(customer).length == 0){
            customer = await store.dispatch('Customer/getCustomer')
        }

        if(!customer || Object.keys(customer).length == 0){
            throw Error('Customer not logged');
        }

        next();
    } catch (error) {           
        next({
            name: 'login'
        })
    }
}