export default function money (value) {    
    if (isNaN(value)) {
        return value;
    }

    var decimals = 2;
    let currency = 'EUR', currencyCountry = 'pt-PT', symbol = '€';     

    var formatter = new Intl.NumberFormat(currencyCountry, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: decimals
    });

    return formatter.format(value);
}