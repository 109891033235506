import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    event: {},  
    eventSlug: null,  
    modalTicket: {},
    templateKey: (new Date).getTime(),
    tickets: [],
  },
  actions,
  getters,
  mutations,
};