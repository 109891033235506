import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    customer: {},
    accessToken: null,
    /**
     * armazena temporariamente os dados do login 
     * por que  sao paginas separadas de login e password
     * @var {Object}
     */
    formLogin: {}
  },
  actions,
  getters,
  mutations,
};