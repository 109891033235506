import {
  axiosInstance
} from "@/plugins/axios-instance"

export default {
  setEvent(context, value) {
    context.commit('SET_EVENT', value)
  },
  setTemplateKey(context, value) {
    context.commit('SET_TEMPLATE_KEY', value);
  },
  setEventSlug(context, value) {
    context.commit('SET_EVENT_SLUG', value)
  },
  setModalTicket(context, value) {
    context.commit('SET_MODAL_TICKET', value)
  },
  async getEvent(context) {
    return await axiosInstance({
        method: 'GET',
        url: 'event/' + context.getters.eventSlug,
      }).then(response => response.data.data)
      .then(data => {
        context.commit('SET_EVENT', data);
        return data;
      })
  },
  async loadTickets(context) {
    return axiosInstance({
        method: 'GET',
        url: 'customer/tickets'
      }).then(response => response.data.data)
      .then(data => {
        context.commit('SET_TICKETS', data)

        return data;
      })
  }
}
