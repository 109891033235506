import i18n from '@/i18n';
import { axiosWithoutLoading } from '@/plugins/axios-instance';

export default {
    loading(context, value) {
      context.commit('setLoading', value)
    },
    setVisitorId(context, value) {
      context.commit('setVisitorId', value);
      localStorage.setItem('zicket-visitorId', value)
    },
    setIsDesktop(context, value){
      context.commit('setIsDesktop', value)
    },
    setUrls(context){
      axiosWithoutLoading({
          method: 'GET',
          url: 'site-urls'
      }).then(response => response.data)
      .then(data => {
          console.log('console site', data)
          context.commit('setUrls', data)
      })
  },
    setMenuOpen(context, value) {
      context.dispatch("setActiveBackdrop", value);
      context.commit("setMenuOpen", value);
    },
    setActiveBackdrop(context, value) {
      context.commit("setActiveBackdrop", value);
      if (!context.getters.activeBackdrop) {
        document.getElementsByTagName("body")[0].classList.remove("overflow-hidden");
        document.getElementsByClassName("backdrop_bar")[0].classList.add("d-none");
      } else {
        document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
        document.getElementsByClassName("backdrop_bar")[0].classList.remove("d-none");
      }
    },
    setLocale(context, value){
        context.commit("setLocale", value);
        localStorage.setItem("zicket-locale", value);
        i18n.locale = value
    },
};
