<template>
  <div id="app" @scroll="handleScroll()">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>

    <HeaderPage v-if="!$route.name?.includes('login')"
        :isScrolled="isScrolled"
        :isScrolledMobile="isScrolledMobile" />

    <main class="content-main">
      <router-view />
    </main>

    <Footer v-if="!$route.name?.includes('login')" />
    <template v-if="!$route.name?.includes('login')">
      <!--- BACKDROP -->
      <div class="backdrop_bar d-none"></div>
      <!-------------------->
    </template>

    <!-- <Cookies /> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import HeaderPage from '@/components/HeaderPage';
import Footer from '@/components/Footer';
import { io } from "socket.io-client";
// import Cookies from '@/components/Cookies/index.vue';

window.io = io;

export default {
    data() {
        return {
            isScrolled: false,
            isScrolledMobile: false
        }
    },
    components: {
        HeaderPage,
        Footer,
        Loading,
        // Cookies
    },
    computed: {
        ...mapGetters('Site', ['isDesktop', 'isLoading', 'visitorId'])
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapActions('Site', ['setIsDesktop', 'setVisitorId', 'setUrls']),
        handleScroll(event) {
            if (event.currentTarget.innerWidth > 992) {
                if (event.currentTarget.scrollY > 100) {
                    this.isScrolled = true;
                } else {
                    this.isScrolled = false;
                }
            } else {
                if (event.currentTarget.scrollY > 100) {
                    this.isScrolledMobile = true;
                } else {
                    this.isScrolledMobile = false;
                }
            }
        }
    },
    mounted() {
        this.setUrls();

        //gera um codigo para visitante
        if(!this.visitorId){
            this.setVisitorId(parseInt(Math.random(Math.floor()) * 1E16));
        }

        if (window.innerWidth > 992) {
            this.setIsDesktop(true);
        } else {
            this.setIsDesktop(false);
        }
    }
}
</script>
<style lang="scss">
@import "./style.scss";
</style>
