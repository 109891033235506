import {
    axiosInstance,
    axiosWithoutLoading
} from "@/plugins/axios-instance";

export default {
    setCustomer(context, value) {
        context.commit('SET_CUSTOMER', value)
    },
    setAccessToken(context, value) {
        context.commit('SET_ACCESS_TOKEN', value)
    },
    setFormLogin(context, value) {
        context.commit('SET_FORM_LOGIN', value)
    },
    logoff(context){
        context.commit('LOGOFF');
        //pediram para redirect para site principal
        let redirect = context.rootGetters['Site/urls'].site;
        document.location.href = `${redirect}`;
    },
    async getCustomer(context) {
        return await axiosInstance({
                method: 'GET',
                url: 'customer'
            }).then(response => response.data.data)
            .then(data => {
                context.commit('SET_CUSTOMER', data);
                return data;
            })
    },
    async updateCustomer(context, formData) {
        await axiosInstance({
                method: 'POST',
                url: 'customer',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            }).then(response => response.data.data)
            .then(data => {
                context.commit('SET_CUSTOMER',data);
            })
    },
    async updateCustomerWithoutLoading(context, formData) {
      await axiosWithoutLoading({
        method: 'POST',
        url: 'customer',
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      }).then(response => response.data.data)
        .then(data => {
          context.commit('SET_CUSTOMER',data);
        })
    },
    async portableCustomer(customerId) {
        console.log("console form" ,customerId);
        await axiosInstance({
                    method: 'POST',
                    url: 'customer/portable',
                    data: customerId,
                    headers: { "Content-Type": "multipart/form-data" }
                 })
                .then((response) => {
                    if (!response.data.success) {
                        var element = document.createElement("a");
                        element.setAttribute(
                            "href",
                            "data:text/plain;charset=utf-8," +
                                encodeURIComponent(response.data.xml)
                        );
                        element.setAttribute(
                            "download",
                            response.data.filename
                        );
                        element.style.display = "none";
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        return;
                    }
                    this.$store.dispatch("setAlert", {
                        type: "success",
                        title: "Sucesso",
                        message: response.data.message.text,
                    });
                })
                .catch((error) => {
                    this.$store.dispatch("setAlert", {
                        type: "error",
                        title: "Error",
                        message: error,
                    });
                });
    },
}
