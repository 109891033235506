export default {
  setLoading(state, value){
    state.loading = value;
  },
  setVisitorId(state, value){
    state.visitorId = value;
  },
  setMenuOpen(state, value) {
    state.menuOpen = value;
  },
  setLocale(state, value){
    state.locale = value;
  },
  setIsDesktop(state, value) {
    state.isDesktop = value;
  },
  setActiveBackdrop(state, value) {
    state.activeBackdrop = value;
  },
  setUrls(state, value){
    state.urls = value
  },
};
