export default {
    isLoading: state => state.loading,
    locale: state => (state.locale || localStorage.getItem('zicket-locale')),
    visitorId: state => (state.visitorId || localStorage.getItem('zicket-visitorId')),
    menuOpen(state) {
      return state.menuOpen;
    },
    activeBackdrop(state) {
      return state.activeBackdrop;
    },
    isDesktop(state) {
      return state.isDesktop;
    },
    urls: state => state.urls,
};
