export default {
    SET_EVENT(state, value){
        state.event = value;
    },
    SET_EVENT_SLUG(state, value){
        state.eventSlug = value;
        localStorage.setItem('zicket-event-slug', value);
    },
    SET_MODAL_TICKET(state, value){
        state.modalTicket = value;
    },
    SET_TEMPLATE_KEY(state, value){
        state.templateKey = value;
    },
    SET_TICKETS(state, value){
        state.tickets = value;
    }
}