<template>
  <aside
    class="aside-menu d-lg-none"
    :class="{ 'aside-menu-active': menuOpen }"
  >
    <div class="aside-menu-itens">
      <div class="aside-menu-itens-header">
        <router-link tag="a" :to="{ name: 'home' }" class="d-block">
          <img
            class="img-fluid"
            src="@/assets/img/logo-zicket-mobile.png"
            alt="logo zicket"
            title="logo zicket"
          />
        </router-link>
        <div class="content-menu">
          <a class="content-menu-item btn-menu" @click="setMenuOpen(false)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <defs>
                <filter
                  id="a"
                  x="0"
                  y="0"
                  width="56"
                  height="56"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dx="1" dy="1" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="1" result="b" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="b" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g transform="translate(-321 -24)">
                <g transform="matrix(1, 0, 0, 1, 321, 24)" filter="url(#a)">
                  <path
                    d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z"
                    transform="translate(2 2)"
                    fill="#c6004f"
                  />
                </g>
                <path
                  d="M14.314,19.016a.731.731,0,0,0,.093-1.028.717.717,0,0,0-1.022-.1L.264,28.738A1.1,1.1,0,0,0,0,29.385a.732.732,0,0,0,.262.561L13.382,40.8a.538.538,0,0,0,.467.25.73.73,0,0,0,.467-1.29L1.861,29.385Z"
                  transform="translate(353.66 80.383) rotate(180)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div class="aside-menu-itens-links">
        <router-link tag="a" :to="{ name: 'home' }" class="links">
          <span @click="setMenuOpen(false)"> Os seus eventos </span>
        </router-link>
        <router-link tag="a" :to="{ name: 'account' }" class="links">
          <span @click="setMenuOpen(false)"> A sua conta </span>
        </router-link>
        <router-link tag="a" :to="{ name: 'orders' }" class="links">
          <span @click="setMenuOpen(false)"> Encomendas </span>
        </router-link>
        <a
          class="btn--logoff"
          @click="
            logoff();
            setMenuOpen(false);
          "
        >
          Terminar Sessão
        </a>
        <!-- <div class="aside-menu-itens-collapse">
          <button class="aside-menu-itens-collapse-btn" v-b-toggle.collapse-1>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 8"><path d="M4.5,0,9,8H0Z" transform="translate(9 8) rotate(180)"/></svg>
            </div>
            PT
          </button>
          <b-collapse id="collapse-1">
            <div class="card-body">
              <a class="card-body-links" href="#"> EN </a>
              <a class="card-body-links" href="#"> ES </a>
            </div>
          </b-collapse>
        </div> -->
        <LanguageList />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LanguageList from "@/components/LanguageList";

export default {
  data() {
    return {};
  },
  components: {
    LanguageList,
  },
  computed: {
    ...mapGetters("Site", ["menuOpen"]),
  },
  methods: {
    ...mapActions("Site", ["setMenuOpen"]),
    ...mapActions("Customer", ["logoff"]),
  },
};
</script>

<style lang="scss">
.aside-menu {
  position: fixed;
  top: 0px;
  width: 80%;
  height: 100%;
  background: var(--white);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 30px;
  transition: 0.8s ease-in-out;
  right: -100%;
  pointer-events: none;
  z-index: 1030;
  box-shadow: 0px 0px 99px rgba(0, 0, 0, 0.7);
  &-active {
    right: 0;
    pointer-events: initial;
  }
  &-itens {
    height: 100%;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 55px 15px 20px 15px;
    }
    &-links {
      .links {
        display: block;
        font-family: "Font Bold";
        color: var(--black) !important;
        font-size: 22px;
        margin-bottom: 0;
        width: 100%;
        padding: 15px;
        transition: 500ms;
        text-decoration: none;
        text-align: right;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      .btn--logoff {
        display: block;
        font-family: "Font Regular";
        color: var(--pink-dark) !important;
        font-size: 16px;
        margin-bottom: 0;
        width: 100%;
        padding: 15px;
        transition: 500ms;
        text-decoration: none;
        text-align: right;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
    .card {
      border: 0;
      background: 0;
      border-radius: 0px;
      &-header {
        border: 0;
        background: 0;
        border-radius: 0px;
        padding: 0;
      }
      .btn-link {
        padding: 15px;
        padding-bottom: 0px;
        font-size: 20px;
        color: var(--black);
        font-family: "Font Bold";
        border: 0;
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: none;
        & > div {
          transition: 500ms;
          margin-left: 10px;
          svg {
            fill: var(--main-color);
            transition: 500ms;
          }
        }
      }
      [aria-expanded="true"] {
        opacity: 0.8;
        & > div {
          transform: rotate(180deg);
        }
      }
      &-body {
        padding: 0;
        &-links {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 40px;
          width: 100%;
          padding: 0px 15px;
          color: var(--black);
          font-size: 18px !important;
          font-family: "Font Medium";
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
      }
    }
    &-collapse {
      &-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-family: "Font Bold";
        color: var(--black) !important;
        font-size: 22px;
        margin-bottom: 0;
        width: 100%;
        padding: 15px;
        background: 0;
        border: 0;
        transition: 500ms;
        text-decoration: none;
        text-align: right;
        & > div {
          transition: 500ms;
          margin-right: 10px;
          svg {
            width: 12px;
            height: 12px;
            fill: var(--black);
            transition: 500ms;
          }
        }
        &[aria-expanded="true"] {
          opacity: 0.8;
          & > div {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
