import {
    axiosInstance
} from "@/plugins/axios-instance";

export default {    
    async loadWallet(context) {
        return await axiosInstance({
                method: 'GET',
                url: 'customer/wallet-event'
            }).then(response => response.data.data)
            .then(data => {
                context.commit('SET_WALLET', data);
                return data;
            })
    },    
}
