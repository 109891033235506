import { cloneDeep } from "lodash";

export default {
    SET_CUSTOMER(state, value){
        state.customer = value;
    },
    LOGOFF(state){
        state.customer = {};
        state.accessToken = null;
        localStorage.removeItem('zicket-customer-token');
    },
    SET_ACCESS_TOKEN(state, value){
        state.accessToken = value;
        localStorage.setItem('zicket-customer-token', value);
    },
    SET_FORM_LOGIN(state, value){
        let props = cloneDeep(state.formLogin);
        state.formLogin = Object.assign(props, value)
    }
}