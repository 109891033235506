import store from "@/store";
import axios from "axios";
import Vue from 'vue';

/**
 * AxiosInstance contém loading no painel
 */
export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
});

axiosInstance.interceptors.request.use(function (config) {
  const locale = localStorage.getItem('zicket-locale') || 'pt-pt';

  config.headers['Authorization'] = store.getters['Customer/accessToken'];

  config.headers['Zicket-Visitor-Id'] = store.getters['Site/visitorId'];
  config.headers['Zicket-Event-Id'] = store.getters['Event/event'].id || '';
  config.headers["X-locale"] = locale;

  store.dispatch('Site/loading', true, {
    root: true
  });

  return config;
});

axiosInstance.interceptors.response.use((response) => {
  store.dispatch('Site/loading', false, {
    root: true
  });

  return response
}, (error) => {
  store.dispatch('Site/loading', false, {
    root: true
  });

  swalError(error)

  throw error;
});

/**
 *
 */
export const axiosWithoutLoading = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
});

axiosWithoutLoading.interceptors.request.use(function (config) {
  const locale = localStorage.getItem('zicket-locale') || 'pt-pt';
  config.headers['Authorization'] = store.getters['Customer/accessToken'];
  config.headers['Zicket-Visitor-Id'] = store.getters['Site/visitorId'];
  config.headers['Zicket-Event-Id'] = store.getters['Event/event'].id || '';
  config.headers["X-locale"] = locale;

  return config;
});

axiosWithoutLoading.interceptors.response.use((response) => {
  return response
}, (error) => {
  swalError(error)

  throw error;
});

/**
 * added no vue os axios
 */
Vue.prototype.$axiosInstance = axiosInstance;
Vue.prototype.$axiosWithoutLoading = axiosWithoutLoading;

/**
 * method error
 * @param {*} error
 */
function swalError(error) {
  let dataError = {
    message: ('message' in error.response ? error.response.message : error.response.statusText)
  };

  if ('data' in error.response && error.response.data) {
    dataError = 'data' in error.response.data ? error.response.data.data : error.response.data;
  }

  Vue.swal.fire({
    html: dataError.message
  })
}
