import { customerHasAuth } from '@/middlewares'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home')
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User'),
    children: [
      {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/User/Login')
      },
      {
        path: 'password',
        name: 'loginPassword',
        component: () => import(/* webpackChunkName: "login" */ '../views/User/LoginPassword')
      },
      {
        path: 'activate-code',
        name: 'loginCode',
        component: () => import(/* webpackChunkName: "login" */ '../views/User/ActivateCode')
      },
      {
        path: 'new-password',
        name: 'loginNewPassword',
        component: () => import(/* webpackChunkName: "login" */ '../views/User/LoginNewPassword')
      },
      {
        path: 'impersonate',
        name: 'loginImpersonate',
        component: () => import(/* webpackChunkName: "login" */ '../views/User/LoginImpersonate')
      },
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/User/Register')
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "account" */ '../views/User/Account'),
    children: [
      {
        path: '/',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '../views/User/Account/MyAccount'),
      },
      {
        path: 'forget',
        name: 'forget',
        component: () => import(/* webpackChunkName: "account" */ '../views/User/Account/Forget')
      },
    ]
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "ticket" */ '../views/Ticket')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/Wallet')
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import(/* webpackChunkName: "movements" */ '../views/Movements')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "info" */ '../views/Info')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders')
  },
  {
    path: '/404',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
})


router.beforeEach(async (to, from, next) => {
  const pageUnprotected = ["login", "register", "loginPassword", "loginCode", "loginNewPassword", "loginImpersonate"];

  if(pageUnprotected.indexOf(to.name) === -1){
    await customerHasAuth(to, from, next)
  }else{
    next();
  }
});

export default router
