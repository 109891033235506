<template>
  <header
    class="content-header"
    :class="[classIsScrolled(), classCssPagesHeader()]"
  >
    <div class="content-header-itens">
      <div class="content-header-itens-div">
        <a
          :href="urls.site"
          class="content-header-itens--logo d-none d-lg-flex"
        >
          <img
            class="img-fluid"
            src="@/assets/img/logo-zicket.png"
            alt="logo ticket"
            title="logo ticket"
          />
        </a>
        <router-link
          :to="{ name: 'home' }"
          class="content-header-itens-mobile d-lg-none"
          v-if="this.$route.name !== '/'"
        >
          <div class="content-header-itens-mobile--icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.747"
              height="13.997"
              viewBox="0 0 8.747 13.997"
            >
              <path
                d="M8.588,18.5a.438.438,0,0,0,.056-.617.43.43,0,0,0-.613-.061L.159,24.33A.658.658,0,0,0,0,24.719a.439.439,0,0,0,.157.337l7.872,6.511a.323.323,0,0,0,.28.15.438.438,0,0,0,.28-.774L1.116,24.719Z"
                transform="translate(0 -17.719)"
              />
            </svg>
          </div>
          <p>
            <template v-if="this.$route.name === 'account'">
              {{ $t('header.myAccount') }}
            </template>
            <template v-else-if="this.$route.name === 'orders'">
              {{ $t('header.orders') }}
            </template>
            <template v-else-if="this.$route.name === 'register'">
              {{ $t('header.register') }}
            </template>
            <template v-else>{{ $t("header.events") }}</template>
          </p>
        </router-link>
        <router-link
          :class="{ 'active-link': $route.name === 'home' }"
          tag="a"
          :to="{ name: 'home' }"
          class="content-header-itens--my-events d-none d-lg-flex"
        >
          {{ $t("header.navigation.yourEvents") }}
        </router-link>
        <router-link
          :class="{ 'active-link': $route.name === 'orders' }"
          tag="a"
          :to="{ name: 'orders' }"
          class="content-header-itens--my-events d-none d-lg-flex"
        >
          {{ $t("header.navigation.yourOrders") }}
        </router-link>
        <router-link
          :class="{ 'active-link': $route.name === 'account' }"
          tag="a"
          :to="{ name: 'account' }"
          class="content-header-itens--my-events d-none d-lg-flex"
        >
          {{ $t("header.navigation.yourAccount") }}
        </router-link>
      </div>
      <div class="content-header-itens-account" :class="classCssPageAccount()">
        <a class="content-user">
          <p v-html="$t('header.welcome_message', { name: fullName })"></p>
        </a>
        <!-- <div class="content-dropdown">
          <a class="content-user--icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.344"
              height="21.875"
              viewBox="0 0 27.344 21.875"
            >
              <path
                d="M352.845,232.443a.769.769,0,0,0-.21.393l-.61,3.051a.385.385,0,0,0,.452.452l3.051-.611a.768.768,0,0,0,.393-.21l5.353-5.353-3.077-3.077Zm11.027-7.045-.9-.9a1.538,1.538,0,0,0-2.175,0l-1.629,1.63,3.077,3.077,1.629-1.629A1.543,1.543,0,0,0,363.872,225.4Z"
                transform="translate(-336.977 -214.473)"
                fill="#fff"
              />
              <path
                d="M9.532,10.938A5.469,5.469,0,1,0,4.1,5.469,5.469,5.469,0,0,0,9.532,10.938Zm2.2,2.051H7.4A7.409,7.409,0,0,0,0,20.4a1.48,1.48,0,0,0,1.481,1.478H13.717a1.746,1.746,0,0,1-.012-.728l.61-3.051A2.129,2.129,0,0,1,14.9,17l1.953-1.953A7.366,7.366,0,0,0,11.736,12.988Z"
                transform="translate(0 0)"
                fill="#fff"
                opacity="0.4"
              />
            </svg>
          </a>
          <div class="content-dropdown--item">
            <router-link tag="a" :to="{ name: 'account' }" class="links">
              A sua conta
            </router-link>
            <router-link tag="a" :to="{ name: 'orders' }" class="links">
              Encomendas
            </router-link>
            <a class="btn--logoff" @click="logoff"> Terminar Sessão </a>
          </div>
        </div> -->
        <!-- <div class="content-dropdown-language d-none d-lg-block">
          <div class="content-dropdown-language--selected">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="8"
              viewBox="0 0 9 8"
            >
              <path
                d="M4.5,0,9,8H0Z"
                transform="translate(9 8) rotate(180)"
                fill="#fff"
              />
            </svg>
            <p>PT</p>
          </div>
          <div class="content-dropdown-language--options">
            <a>EN</a>
            <a>ES</a>
          </div>
        </div> -->
        <LanguageList />
      </div>
      <template v-if="$route.name === 'home' && !isDesktop">
        <button class="content-header-itens--menu" @click="setMenuOpen(true)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
          >
            <g transform="translate(-311 -42)">
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="translate(311 42)"
                fill="#c6004f"
              />
              <g transform="translate(0 -0.5)">
                <line
                  x2="24"
                  transform="translate(324 59.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
                <line
                  x2="24"
                  transform="translate(324 67)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
                <line
                  x2="24"
                  transform="translate(324 75)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
                />
              </g>
            </g>
          </svg>
        </button>
        <Menu />
      </template>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Menu from "@/components/Menu";
import LanguageList from "@/components/LanguageList";

export default {
  props: {
    isScrolled: Boolean,
    isScrolledMobile: Boolean,
  },
  components: {
    LanguageList,
    Menu,
  },
  computed: {
    ...mapGetters("Customer", ["customer"]),
    ...mapGetters("Site", ["isDesktop", "urls"]),
    fullName() {
      return `${this.customer.first_name} ${this.customer.last_name}`;
    },
  },
  methods: {
    ...mapActions("Site", ["setMenuOpen"]),
    ...mapActions("Customer", ["logoff"]),
    classIsScrolled() {
      if (this.isScrolled || this.isScrolledMobile) {
        return "content-header-scrolled";
      }
    },
    classCssPagesHeader() {
      if (this.$route.name === "home") {
        return "content-header-event";
      }
      if (
        ["tickets", "wallet", "transactions", "info"].indexOf(
          this.$route.name
        ) > -1
      ) {
        return "content-header-ticket";
      }
    },
    classCssPageAccount() {
      if (
        [
          "account",
          "tickets",
          "wallet",
          "transactions",
          "info",
          "orders",
        ].indexOf(this.$route.name) > -1
      ) {
        return "d-none d-lg-flex";
      }
    },
  },
};
</script>

<style lang="scss">
.content-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 140px;
  background: var(--black);
  padding: 0px 50px;
  z-index: 13;
  transition: 500ms;

  @media (min-width: 992px) and (max-height: 800px) {
    height: 100px;
  }

  @media (min-width: 1920px) {
    padding: 0px 125px;
  }

  @media (max-width: 991px) {
    height: 120px;
    padding: 0;
  }

  &-itens {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-div {
      width: 65%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 80px;
      @media (max-width: 1299px) {
        width: 100%;
        gap: 30px;
      }
    }

    &--logo {
      height: 100%;
      display: flex;
      align-items: center;
      @media (min-width: 992px) and (max-width: 1199px) {
        max-width: 130px;
      }
    }

    &-mobile {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 25px 15px;

      &--icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white);
        border-radius: 100%;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
        margin-right: 10px;
      }

      p {
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--white);
        margin-bottom: 0;
      }
    }

    &-account {
      display: flex;
      align-items: center;
      height: 100%;

      .content-user {
        display: block;
        margin-right: 20px;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin-right: 0px;
        }

        p {
          font-size: 16px;
          font-family: "Font Bold";
          color: var(--pink-dark);
          text-align: right;
          line-height: 1.2;
          width: 170px;
          margin-bottom: 0;
          @media (max-width: 991px) {
            font-size: 18px;
            text-align: left;
          }
          span {
            display: block;
            font-family: "Font Regular";
            color: var(--white);
            white-space: nowrap;
            @media (max-width: 991px) {
              color: var(--black);
            }
          }
        }

        &--icon {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--pink-dark);
          border-radius: 100%;
          transition: 500ms;

          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }

      .content-dropdown {
        position: relative;
        padding: 10px 0px;
        &:hover,
        &:focus {
          cursor: pointer;
          .content-dropdown--item {
            opacity: 1;
            pointer-events: initial;
          }
        }
        &--item {
          position: absolute;
          width: 220px;
          right: 0;
          top: 100%;
          padding: 20px 0px;
          background: var(--white);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          opacity: 0;
          pointer-events: none;
          .links {
            display: block;
            font-size: 22px;
            font-family: "Font Regular";
            color: var(--black);
            text-align: right;
            padding: 10px 30px;
            line-height: 1.2;
            transition: 500ms;
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.6;
            }
          }
          .btn--logoff {
            display: block;
            font-size: 16px;
            font-family: "Font Regular";
            color: var(--pink-dark);
            text-align: right;
            padding: 10px 30px;
            line-height: 1.2;
            transition: 500ms;
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.6;
            }
          }
        }
      }
    }

    &--my-events {
      display: block;
      font-size: 20px;
      font-family: "Font Regular";
      color: var(--white);
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;
      transition: 500ms;
      @media (max-width: 1299px) {
        font-size: 16px;
      }
      &:hover {
        cursor: pointer;
        color: var(--white);
        opacity: 0.8;
      }
    }

    &--menu {
      padding: 0;
      background: 0;
      border: 0;
    }

    .content-dropdown-language {
      position: relative;
      width: 60px;
      height: 50px;
      border: 1px solid var(--white);
      border-radius: 5px;
      margin-left: 60px;
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-left: 30px;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        .content-dropdown-language--options {
          opacity: 1;
          pointer-events: initial;
        }
      }
      &--selected {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 18px;
          font-family: "Font Medium";
          color: var(--white);
          margin-left: 5px;
          margin-bottom: 0;
        }
      }
      &--options {
        position: absolute;
        width: 100px;
        right: 0;
        top: 100%;
        padding: 20px 0px;
        background: var(--white);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        opacity: 0;
        pointer-events: none;
        a {
          display: block;
          font-size: 22px;
          font-family: "Font Regular";
          color: var(--black) !important;
          text-align: center;
          padding: 10px 30px;
          line-height: 1.2;
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
    }
  }

  &-event {
    @media (max-width: 991px) {
      position: relative;
      padding: 15px 20px;
      background: var(--white);
      .content-header-itens {
        width: 100%;
        &-div {
          display: none;
        }
        &-account {
          width: 100%;
          .content-user {
            margin-right: 0;

            p {
              color: var(--black);
            }
            &--icon {
              display: none;
            }
          }
        }
      }
    }
  }

  &-ticket {
    position: absolute;
    background: transparent;
  }

  .active-link {
    border-color: var(--white);
  }
}
</style>
