import Vue from 'vue'
import Vuex from 'vuex'
import Site from './Site';
import Customer from './Customer';
import Event from './Event';
import Wallet from './Wallet';
import Checkout from '@monorepo/zicket-vue-checkout/src/store/Checkout';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Site,
    Customer,
    Event ,
    Wallet,
    Checkout
  }
})
